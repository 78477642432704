a {
  color: rgb(163, 89, 245);
  text-decoration: none;

  &:hover {
    color: rgb(228, 161, 255);
  }
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-text {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin: 1rem 0;
}

.pf-tagline {
  max-width: 100vw;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 3rem 0;
}

.fox-search {
  max-width: 100vw;
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  padding: 0.5rem;
  font-size: 1.75rem;
  font-weight: bold;
  margin: 2rem 0;

  &::placeholder {
    color: #ddd;
  }
}

.fox-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.fox-link {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  background-color: #515151;
  border: 2px solid #808080;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    background-color: #808080;
    border: 2px solid #515151;
  }
}
