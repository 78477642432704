
/*
      www.OnlineWebFonts.Com
      You must credit the author Copy this link on your web
      <div>Font made from <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {
  font-family: "Code 7x5";
  src: url("../../assets/fonts/Code\ 7x5.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

.experience-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: -1;
}

.experience-title, .experience-fox-name {
  color: #fff;
}

.top {
  &-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-right {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.bottom {
  &-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.display {
  display: flex;

  background-color: rgba(0, 0, 0, 0.85);
  border: 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: aliceblue;
  color: #fff;

  margin: 10px;
  min-width: 250px;
  max-width: 500px;

  overflow: hidden;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: calc(100% - 20px);
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
}

.internalDisplay {
  padding: min(20px, 3vw);

  table {
    padding: 0;
    margin: 0;

    tr {
      padding-top: 10px;

      .trait-name {
        font-weight: bold;
      }
    }
  }
}

.heading {
  display: flex;
}

.component-title {
  font-family: 'Code 7x5',sans-serif;
  margin-top: 0;
  margin-bottom: 6px;
  display: block;
}

.component-subtitle {
  display:block;
  margin-top: 6px;
}

.pfp {
  border-radius: 5px;
  height: 60px;
  width: 60px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #fff;
}

.component-title {
  margin: auto;
  display: grid;
}

.heading {
  padding-left: 8px;
  margin-bottom: 10px;
}

table {
  margin: auto;
  border-collapse: separate;
  border-spacing: 5px 2px;

  th, td {
    line-height: 1.1;
    vertical-align: baseline;
    text-align: left;
    padding-top: 5px;

    max-lines: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    text-align: left;
    font-weight: bold;
  }

  td {
    &.fox-id {
      max-width: calc(min(75px, 25%) - 25px);
      min-width: calc(min(75px, 25%) - 25px);
    }

    &.fox-name {
      max-width: min(30vw, 250px);
      min-width: min(30vw, 250px);
    }

    &.trait-name, &.trait-value {
      max-width: calc(50vw);
      white-space: normal;
      text-overflow: ellipsis;
    }

    &.similarity {
      text-align: right;
    }
  }
}

.fox-sublink {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.back-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.button-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.twitter-share-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: 2px solid #fff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  margin: 5px;
  padding: 5px;
  min-width: 55px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  .button-icon {
    width: 45px;
    height: 45px;

    transition: all 0.2s ease-in-out;
    filter: invert(1);

    &:hover {
      filter: invert(0);
    }
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:active {
    transform: translateY(2px);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }
}
